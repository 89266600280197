import React, { useEffect, useRef, useState } from 'react'
import MembershipLevel from '../components/MembershipLevel'
import pierre from "../images/Pierre.jpg"
import { useNavigate } from 'react-router-dom'

const Vbenefits = [
  "Open invite to all practices",
  "Shootaround with a student-athlete after a practice (with a guest)",
  "Receive an official Coaches Rice 'polo' and other exclusive gear",
  "Team autographed basketball",
  "Invite to monthly zoom meeting with Rice Head Coach Rob Lanier, other coaches and personnel",
  "Personal note from Rice Basketball student-athlete",
  "Invite to Owl's Nest exclusive events",
  
]

const Sbenefits = [
  "Invite to attend a closed practice (with guests)",
  "Invite to monthly zoom meeting with Rice Head Coach Rob Lanier, other coaches and personnel",
  "Personal note from Rice Basketball student-athlete",
  "Invite to Owl's Nest exclusive events"
]

const Mbenefits = [
  "Invite to season preview zoom meeting in November with Rice Head Coach Rob Lanier",
  "Personal note from Rice Basketball student-athlete",
  "Invite to Owl's Nest exclusive events"
]
export default function MembershipPage(props) {
  const heightRef = useRef(null);
  const [bgImg, setBgImg] = useState(<></>);

  const navigate = useNavigate();

  useEffect(()=>{
    setBgImg(<img src={pierre} className='membership-bg-img'/>)
    props.setCustom(true);
  }, [])

  return (
    <div className='basic-page-setup open-sans'>
      <div className='row'>
        <div className='membership-bg-img-frame col-12 col-md-5' style={{height: `${heightRef?.current?.clientHeight}px`}}>
          {bgImg}
        </div>
        <div className='px-0 px-md-5 pt-0 pt-md-4 col-12 col-md-7' ref={heightRef}>
          <MembershipLevel setCustom={props.setCustom} setAmount={props.setAmount} levelName = "Valedictorian" annualFee="10,000" benefits={Vbenefits}/>
          <MembershipLevel setCustom={props.setCustom} setAmount={props.setAmount} levelName = "Summa Cum Laude" annualFee="5,000" benefits={Sbenefits}/>
          <MembershipLevel setCustom={props.setCustom} setAmount={props.setAmount} levelName = "Magna Cum Laude" annualFee="2,500" benefits={Mbenefits}/>
          <div className='w-100 d-flex flex-column align-items-start ps-5 open-sans my-5'>
              <h2 className='fw-bold text-blue mb-3'>CUSTOM CONTRIBUTIONS</h2>
              <h5 className='mb-3'>All contributions welcome.</h5>
              <button className='btn join-club-btn-dark'
                onClick={(e)=>{e.preventDefault(); props.setCustom(true); navigate("/joinclub")}}
              >Click Here</button>
          </div>
          <div className='my-2 text-secondary'>*Not a tax deductible donation</div>
        </div>
      </div>
      
    </div>
  )
}
