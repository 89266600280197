import React from 'react'

export default function ContactPage() {
  return (
    <div className='basic-page-setup pt-5'>
      <h1 className='text-blue fw-bold mt-5 mb-4'>Contact Us</h1>
      <div className='d-flex flex-wrap justify-content-center'>
        <div className='mx-5'>
          <h5 className='fw-bold text-blue'>J.T. Trauber -- Rice 2019</h5>
          <a className='link-dark link-underline-light' type='email' href='mailto:owlsnesthoops@gmail.com'>owlsnesthoops@gmail.com</a>
        </div>
        <div className='mx-5 d-flex flex-column'>
          <h5 className='fw-bold text-blue'>Randy Block -- Rice 1994</h5>
          <a className='link-dark link-underline-light' type='email' href='mailto:randyblock@performancelp.com'>randyblock@performancelp.com</a>
          <a className='link-dark link-underline-light' type="number" href="tel:214-497-5004">214-497-5004</a>
        </div>
      </div>

      <button className='btn join-club-btn-dark mb-5 mt-3'><a className='navbar-brand' href='/membership'>Join the Club</a></button>
      
    </div>
  )
}
