import React, { useEffect, useState } from 'react'
import { useFireStorage } from '../firebase/useFireStorage.js'
export default function GalleryPage() {

  let {imageUrls} = useFireStorage()
  
  return (
    <div className='basic-page-setup pt-5 pb-3'>
        <h1 className='text-blue fw-bold mt-5 mb-5'>Event Photo Gallery</h1>
        <div className='row px-2 px-lg-5 mx-0 mx-lg-5'>
        {
          imageUrls.map((url, idx)=>{
            return (
              <div key={idx} className='col-6 col-lg-4 p-1 gallery-img-frame'>
                 <img src={url}/>
              </div>
             
            )
          })
        }
        </div>
        
    </div>
  )
}
