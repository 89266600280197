import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

export default function Paypal(props) {
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const amountVal = props.amount;

    const onCreateOrder = (data,actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: amountVal,
                    },
                },
            ],
        });
    }

    const onApproveOrder = (data,actions) => {
        return actions.order.capture().then((details) => {
            const name = details.payer.name.given_name;
            props.setPaymentComplete(true);
            alert(`Transaction completed by ${name}`);
        });
    }

    return (
        <div className="checkout">
            {isPending ? <p>LOADING...</p> : (
                <PayPalButtons 
                    style={{ layout: "vertical" }}
                    createOrder={(data, actions) => onCreateOrder(data, actions)}
                    onApprove={(data, actions) => onApproveOrder(data, actions)}
                />
            )}
        </div>
    );
}