// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, ref, listAll, getDownloadURL} from "firebase/storage";
import { useEffect, useState } from "react";

const firebaseConfig = {
  apiKey: "AIzaSyAr5tfdITy4pN3tq_ETDCGfvO8jm3MYuFs",
  authDomain: "owlsnesthoops-c0cce.firebaseapp.com",
  projectId: "owlsnesthoops-c0cce",
  storageBucket: "owlsnesthoops-c0cce.appspot.com",
  messagingSenderId: "306606567716",
  appId: "1:306606567716:web:a11a86577ab6b70f9f43b1",
  storageBucket: 'gs://owlsnesthoops-c0cce.appspot.com',
};

function useFireStorage() {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    const storage = getStorage();

    // Create a reference under which you want to list
    const listRef = ref(storage, 'Event Image Gallery');

    const [imageUrls, setImageUrls] = useState([]);

    useEffect(()=>{
        setImageUrls([])
        // Find all the prefixes and items.
        listAll(listRef)
        .then((res) => {
            let urls = []
            res.items.forEach((itemRef) => {
            // All the items under listRef.
                getDownloadURL(ref(storage, itemRef.fullPath))
                    .then((url) => {
                        urls.push(url)
                        setImageUrls([...urls])
                    })
                    .catch((error) => {
                        // Handle any errors
                        alert("An error occurred during url retrievement. Please refresh the page.")
                        console.log(error)
                    });
            }
        ); 
        }).catch((error) => {
            // Uh-oh, an error occurred!
            alert("An error occurred during image retrievement. Please refresh the page.")
            console.log(error)
        });
    }, [])

    return {imageUrls}
}

export {useFireStorage}



