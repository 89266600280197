import React from 'react'
import logoOwl from "../images/Owls Nest Hoops Logo owl.png"
import logoWords from "../images/Owls Nest Hoops Logo words.png"

export default function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg bg-white fixed-top p-0">
        <div className="container-fluid open-sans fw-bold">
            <a className="navbar-brand ms-2 d-flex align-items-center" href="/">
                <img src={logoOwl} style={{width: "7vw", minWidth: "50px"}}/>
                <img src={logoWords} style={{width: "15vw", minWidth: "150px"}}/>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-center align-items-center flex-grow-1 pe-3">
                    <li className="nav-item">
                        <a className="nav-link" aria-current="page" href="/about">About Us</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link for-membership" href="/membership">Membership Levels</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/partner">Corporate Partners</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/gallery">Event Photo Gallery</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="/contact">Contact</a>
                    </li>
                </ul>
            </div>
            </div>
        </div>
    </nav>
  )
}
