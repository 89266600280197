import React from 'react'
import mullins from "../images/Mullins.jpg"
import AboutProfile from '../components/AboutProfile'
import rob from "../images/HC Rob Lanier Mug.jpg"
import tommy from "../images/Tommy McClelland Headshot.jpg"

export default function AboutPage() {
  return (
    <div className='basic-page-setup'>
      <div className='w-100'>
        <img src={mullins} style={{width: "100%"}}/>
      </div>
      <h1 className='text-blue fw-bold mt-5 mb-3'>Who We Are</h1>
      <div className='px-5 mx-0 mx-lg-5 mb-5'>
        Owl’s Nest Hoops is an organization in the Name, Image and Likeness (NIL) space, <br/> 
        dedicated to providing NIL opportunities for Rice student-athletes on the Men’s Basketball team. <br/>
        The student-athletes will earn compensation with their Name, Image and Likeness by promoting<br/> 
        businesses, charities, and other non-profits through endorsements, social media, and personal appearances.
      </div>

      <h1 className='text-blue fw-bold mt-5 mb-3'>Testimonials</h1>
      <div className='d-flex justify-content-center flex-wrap mb-3'>
        <AboutProfile pic={rob} 
          name = "Rob Lanier"
          position = "Men's Basketball Head Coach"
          quote = "We are excited about Rice Basketball's future and the role that our alumni and community will play in supporting us." 

        />
        <AboutProfile pic={tommy} 
          name = "Tommy McClelland"
          position = "Vice President & Director of Athletics"
          quote = "Rice Basketball is building for a tradition of success and our supporters are essential to that goal." 
        />
      </div>   
    </div>
  )
}
