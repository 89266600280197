import React, { useEffect, useState } from 'react'
import Paypal from '../components/Paypal'
import pierre from "../images/Pierre.jpg"

export default function PaymentPage(props) {
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [customShow, setCustomShow] = useState(false);
  const [customAmount, setCustomAmount] = useState(100);
  const [curAmount, setCurAmount] = useState("5000");
  const [ready, setReady] = useState(false);

  useEffect(()=>{
    setCurAmount(props.amount.replace(",", ''));
    if (props.custom) {
      setCustomShow(true);
    }
    setReady(true);
  }, [])

  return (
    <div className='basic-page-setup open-sans d-flex flex-wrap w-100'>
      <div className='payment-img-frame'>
        <img src={pierre} className='payment-img'/>
      </div>
      <div className='payment-btn-frame'>
        <h2 className='text-blue fw-bold'>Thanks for supporting us.</h2>
        <h4 className='mb-4'>{`Amount due: $${props.custom? curAmount: props.amount}`}</h4>
        {
          ready && (!customShow)?
          <div className='paypal-wrapper'>
            <Paypal amount={curAmount} setPaymentComplete={setPaymentComplete}/>
          </div>
          
          :
          <></>
        }

        {
          customShow ?
          <div className='payment-cover'>
            <h4>Enter the amount you wish to contribute: </h4>
            <form className='w-50 d-flex flex-column align-items-center' 
              onSubmit={(e)=> {e.preventDefault(); setCurAmount(`${customAmount}`); setCustomShow(false)}}
            >
              <div class="input-group my-2">
                <span class="input-group-text" id="basic-addon1">$</span>
                <input type="number" class="form-control" placeholder="100.00" value={customAmount} step={0.01} min={0}
                  onChange={(e)=>setCustomAmount(e.target.value)}
                />
              </div>
              <div className='d-flex w-100 justify-content-end'>
                <button type='submit' className='btn mt-2 join-club-btn-dark'>Next</button>
              </div>
            </form>
            
          </div>
          :
          <></>
        }
        {
          paymentComplete ?
          <div className='payment-cover'>
            <div className='mb-2'>Payment success!</div>
            <h3 className='text-blue fw-bold'>Welcome to the club.</h3>
          </div>
          :
          <></>
        }
        
      </div>
      
    </div>
  )
}
