import React from 'react'

export default function AboutProfile(props) {
  return (
    <div className='profile-frame'>
        <img style={{width: "100%"}} src={props.pic}/>
        <div className='p-4'>
            <div className='mt-3'>
                <h5 className='fw-bold text-blue'>{props.name}</h5>
                <div className='text-light-emphasis'>{props.position}</div>
            </div>
            <div className='mt-3' style={{fontSize: "medium"}}>"{props.quote}"</div>
        </div>
    </div>
    
  )
}
