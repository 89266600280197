import React from 'react'
import pierre from "../images/Pierre.jpg"

export default function MembershipLevelSm(props) {
  return (
    <div className='member-sm-frame'>
        <div className='d-flex flex-column align-items-center p-3'>
            <div className='position-relative'>
                <img src={pierre} style={{width: "100%"}}/>
                <div className='img-filter d-flex justify-content-center align-items-center'>
                    <h3 className='fw-bold text-white dark-drop-shadow mb-3'>{props.levelName.toUpperCase()} CLUB</h3>
                </div>
            </div>
            
            
            <h5 className='mt-3 mb-1 fw-bold'>{`$${props.annualFee} annual fee`}</h5>
            <a className='navbar-brand link-text' href='/membership'>Become a member <i className="bi bi-arrow-right-circle"></i></a>
        </div>
        
    </div>
  )
}
