import React from 'react'

export default function Footer() {
  return (
    <div className='footer-frame'>
        <div style={{fontSize: 'small'}} className='text-blue'>©2024 Owls Nest Hoops, LLC</div>
        <div style={{fontSize: 'x-small'}}>Developed by Alexia Yuening Huang</div>
    </div>
    
  )
}
