import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {PayPalScriptProvider} from "@paypal/react-paypal-js";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "bootstrap-icons/font/bootstrap-icons.css";

import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import MembershipPage from './pages/MembershipPage';
import PartnerPage from './pages/PartnerPage';
import ContactPage from './pages/ContactPage';
import PaymentPage from './pages/PaymentPage';
import GalleryPage from './pages/GalleryPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

import "./styling.css"
import { useState } from 'react';

function App() {
  const initialOptions = {
    "client-id": "AQFtjGxahDFI1JEpa-nDaOD1dtTB_y1nThuYyuV2KLalz0SqNqrasEoRwnv8wOk_wDH-V7_3PrIUZwkp",
    currency: "USD",
    intent: "capture",
  };

  const [amount, setAmount] = useState("0.01");
  const [custom, setCustom] = useState(false);

  return (
    <PayPalScriptProvider options={initialOptions}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<HomePage/>}/>
          <Route path='/about' element={<AboutPage/>}/>
          <Route path='/membership' element={<MembershipPage setCustom={setCustom} setAmount={setAmount}/>} />
          <Route path='/joinclub' element={<PaymentPage amount={amount} custom={custom} setAmount={setAmount}/>}/>
          <Route path='/partner' element={<PartnerPage/>}/>
          <Route path='/gallery' element={<GalleryPage/>}/>
          <Route path='/contact' element={<ContactPage/>}/>
        </Routes>
        <Navbar/>
        <Footer/>
      </BrowserRouter>
    </PayPalScriptProvider>
  );
}

export default App;
