import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function MembershipLevel(props) {
    const navigate = useNavigate();
  return (
    <div className='w-100 d-flex flex-column align-items-start ps-5 open-sans my-5'>
       
        <h2 className='fw-bold text-blue mb-3'>{props.levelName.toUpperCase()} CLUB</h2>
        <h5 className='mb-2 fw-bold'>{`$${props.annualFee} annual fee`}</h5>
        <div className='text-start'>
            {
                props.benefits.map((benefit, idx)=>{
                    return (
                        <li key={idx} className='my-3'>
                            {benefit}
                        </li>
                    )
                })
            }
        </div>
        <button className='btn join-club-btn-dark' 
            onClick={(e)=>{
                e.preventDefault();
                props.setAmount(props.annualFee);
                props.setCustom(false);
                navigate("/joinclub")
            }}>
                Join the Club
        </button>
    </div>
  )
}
