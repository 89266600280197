import React from 'react'
import fiedler from "../images/Fiedler.jpg"

export default function PartnerPage() {
  return (
    <div className='basic-page-setup'>
      <img src={fiedler} style={{width: "100%"}}/>
      <div className='d-flex flex-column align-items-center my-5 fs-6 mx-3'>
        <div className='mb-3 fs-5'>The <i className='fw-bold'>Wisest</i> way to promote your brand is to <br/>
          associate with the smartest and most athletic student-athletes.</div>
        <div className='mb-2 fs-5'><a className='navbar-brand text-blue fw-bold' href='/contact'>Contact us</a> to learn how your</div>
        Company <br/>
        Restaurant <br/>
        Store <br/>
        Law firm <br/>
        Medical Practice <br/>
        Hotel <br/>
        <div className='my-2 fs-5'>can affordably benefit from Rice Men’s Basketball Players’</div>
        Endorsements <br/>
        Personal Appearances <br/>
        Social Media Promotions
      </div>
    </div>
  )
}
