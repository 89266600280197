import React, { useEffect, useState } from 'react'

import olivari from "../images/Olivari.jpg"
import Fiedler from "../images/Fiedler.jpg"
import Travis from "../images/Travis.jpg"
import rob from "../images/HC Rob Lanier Mug.jpg"
import tommy from "../images/Tommy McClelland Headshot.jpg"
import logo from "../images/Owls Nest Hoops Logo white words.png"

import AboutProfile from '../components/AboutProfile'
import MembershipLevelSm from '../components/MembershipLevelSm'

const rollingImgs = [
  <img className='rolling-img2' src={olivari}/>,
  <img className='rolling-img' src={Fiedler}/>,
  <img className='rolling-img2' src={Travis}/>,
  <img className='rolling-img' src={olivari}/>,]

export default function HomePage() {
  const [rollingImg, setRollingImg] = useState(
    <img 
      className='rolling-img'
      src={olivari}/>
  );

  useEffect(()=>{
    var count = 0
    setInterval(()=>{
      setRollingImg(rollingImgs[count])
      count += 1
      if (count >= 4) {
        count = 0
      }
    }, 3000)
  }, [])

  return (
    <div className='pt-5 open-sans d-flex flex-column align-items-center'>
        <div style={{height: "95vh", width: "100vw", overflow: "hidden"}} className='position-relative'>
            {rollingImg}
            <div className='img-filter'></div>
            <div className='superimpose'>
               <img src={logo} style={{width: "35vw", minWidth: "300px"}} className='dark-drop-shadow'/>
               <h3 className='text-white dark-drop-shadow mb-3'>Rice's Men's Basketball NIL</h3>
               <button className='btn join-club-btn-dark-lh border-light fs-5 mt-1'><a className='navbar-brand' href='/membership'>Join the club</a></button>
            </div>
        </div>
        <h1 className='text-blue fw-bold mt-5 mb-3'>Testimonials</h1>
        <div className='d-flex justify-content-center flex-wrap'>
          <AboutProfile pic={rob} 
            name = "Rob Lanier"
            position = "Men's Basketball Head Coach"
            quote = "We are excited about Rice Basketball's future and the role that our alumni and community will play in supporting us." 

          />
          <AboutProfile pic={tommy} 
            name = "Tommy McClelland"
            position = "Vice President & Director of Athletics"
            quote = "Rice Basketball is building for a tradition of success and our supporters are essential to that goal." 
          />
        </div>

        <h1 className='text-blue fw-bold mt-5 mb-3'>Membership Levels</h1>
        <div className='d-flex justify-content-center flex-wrap'>
          <MembershipLevelSm levelName="Valedictorian" annualFee="10,000"/>
          <MembershipLevelSm levelName="Summa Cum Laude" annualFee="5,000"/>
          <MembershipLevelSm levelName="Magna Cum Laude" annualFee="2,500"/>
        </div>
        <button className='btn join-club-btn-dark mb-5 mt-3'><a className='navbar-brand' href='/membership'>View all membership levels</a></button>
    </div>
  )
}
